<template>
  <div class="show-img-wrapper">
    <div v-if="imgSrc" class="_pic">
      <img :src="imgSrc" alt="">
<!--      <span v-if="id==='4634befe-d942-45b5-8bb1-7bc58bc51b16'" class="jump_btn_jsz_bxga" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/builderEquity/index.html?appid=scban')"></span>-->
      <span v-if="id==='56549731-afc9-4bf7-8371-3dc9bace11d2'" class="jump_btn_jsz_in99_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='56549731-afc9-4bf7-8371-3dc9bace11d2'" class="jump_btn_jsz_in99_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <span v-if="id==='2af4b1f7-81ae-486d-a803-e777c779f5c5'" class="jump_btn_jsz_wfj" @click="onClickPageLink('/shop_list?id=47cb6616-0ed9-4f15-8e34-bce97771ae43')"></span>
      <span v-if="id==='3c2ca7c4-f11b-43df-921c-52bbc32748e8'" class="jump_btn_jsz_in99_1210_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='3c2ca7c4-f11b-43df-921c-52bbc32748e8'" class="jump_btn_jsz_in99_1210_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <span v-if="id==='d06b0141-0ec4-4fce-b54b-56f3aad56918'" class="jump_btn_jdk_bx" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/72/P7200.html')"></span>
<!--      <span v-if="id==='4634befe-d942-45b5-8bb1-7bc58bc51b16'" class="jump_btn_jsz_bx" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/builderEquity/index.html')"></span>-->
      <span v-if="id==='c79efb1d-a1ec-4bd9-9f39-8388b887b3cc'" class="jump_btn_jsz_in99_1210_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='c79efb1d-a1ec-4bd9-9f39-8388b887b3cc'" class="jump_btn_jsz_in99_1210_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <!-- 尊师卡 绿色理赔-->
      <span v-if="id==='a83b57a8-1ab8-4b27-a4d8-7eb0cd20d542'" class="jump_btn_zsk_lp_a" @click="onClickPageLink('/show_img2?id=f7fb243c-91ca-47a9-8bcf-e85a7724533a')"></span>
      <span v-if="id==='a83b57a8-1ab8-4b27-a4d8-7eb0cd20d542'" class="jump_btn_zsk_lp_b" @click="onClickPageLink('/show_img2?id=63652647-1e69-44a8-bfe1-3cc0aa123185')"></span>
      <!-- 尊师卡 贷款-->
      <span v-if="id==='1ed26bad-3617-4ed7-8c5d-ac6dd8b79d95'" class="jump_btn_zsk_dk_a" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/jld.do')"></span>
     <!-- 蜀农卡 -->
      <span v-if="id==='4dd5d170-f7e6-4dec-b647-1c3459c3280f'" class="jump_btn_slk_nz_a" @click="onClickPageLink('/map?store_type=80044&card_id=8')"></span>
      <span v-if="id==='4dd5d170-f7e6-4dec-b647-1c3459c3280f'" class="jump_btn_slk_nz_c" @click="onClickPageLink('/map?store_type=110085&card_id=8')"></span>
      <span v-if="id==='4dd5d170-f7e6-4dec-b647-1c3459c3280f'" class="jump_btn_slk_nz_b" @click="onClickPageLink('/map?store_type=80045&card_id=8')"></span>
      <!-- 川航联名卡 -->
      <!-- 1 -->
<!--      <span v-if="id==='7778e972-41e9-462d-92e2-32be26f40eaa'" class="jump_btn_ch_lmk_ts1" @click="onClickPageLink('https://mnwx.sichuanair.com/www/wxmp/gomqindex?id=31')"></span>-->
      <!-- 2 -->
      <span v-if="id==='afdf592e-11ea-4d23-9d4b-dbd95945c771'" class="jump_btn_ch_lmk_ts2" @click="onClickPageLink('https://shop91667236.youzan.com/v2/showcase/homepage?alias=N0SMsAFIyD')"></span>
      <!-- 3 -->
      <span v-if="id==='62aafedc-806c-4eca-ac5a-36c249548102'" class="jump_btn_ch_lmk_ts3a" @click="onClickPageLink('/show_img2?id=379b29d1-2872-49e5-abb1-a954a747ea0a')"></span>
      <span v-if="id==='62aafedc-806c-4eca-ac5a-36c249548102'" class="jump_btn_ch_lmk_ts3b" @click="onClickPageLink('/show_img2?id=d0e5bf0f-af73-49e3-9a25-034f373ca907')"></span>
      <!-- 4 -->
      <span v-if="id==='cbf669cf-8a68-4557-a586-13671ec9abb3'" class="jump_btn_ch_lmk_ts4" @click="onClickPageLink('https://ground.aerosatlink.com/miniProgram?id=202301')"></span>
      <!-- 5 -->
      <span v-if="id==='61edef94-bcab-40ec-a768-f4d9a0028028'" class="jump_btn_ch_lmk_ts5" @click="onClickPageLink('https://h5.vc.nervenets.cn/#/selfPage?id=2043692')"></span>
      <!-- 6 -->
      <span v-if="id==='1cab7bb7-eb1d-4930-b94d-065a7d3c9526'" class="jump_btn_ch_lmk_ts6" @click="onClickPageLink('https://shop91667236.youzan.com/v2/showcase/homepage?alias=N0SMsAFIyD')"></span>
      <!-- 7 -->
      <span v-if="id==='9187f44b-6eaa-4351-a97f-f47cad7545db'" class="jump_btn_ch_lmk_ts7a" @click="onClickPageLink('/show_img2?id=fdec5ca5-40d6-4e85-9d41-17d9308e5b6c')"></span>
      <span v-if="id==='9187f44b-6eaa-4351-a97f-f47cad7545db'" class="jump_btn_ch_lmk_ts7b" @click="onClickPageLink('/show_img2?id=fdc91fa0-099c-4236-b17e-36f85cfaae8a')"></span>
      <!-- 8 -->
      <span v-if="id==='4968bfb4-be0d-488b-b125-aa74cc19d749'" class="jump_btn_ch_lmk_ts8a" @click="onClickPageLink('/map?store_type=110221&card_id=10')"></span>
      <span v-if="id==='4968bfb4-be0d-488b-b125-aa74cc19d749'" class="jump_btn_ch_lmk_ts8b" @click="onClickPageLink('/show_img2?id=d3d4cf6f-a82a-4773-997a-fe085a36eacb')"></span>
      <span v-if="id==='4968bfb4-be0d-488b-b125-aa74cc19d749'" class="jump_btn_ch_lmk_ts8c" @click="onClickPageLink('https://wx31c0a3862b4939a8.wx.gcihotel.net/mall2/?/#/polymerization?id=8c4e184bb8f74917b73ecdbc6118eb4f')"></span>

      <!-- 9 -->
      <span v-if="id==='d587d631-8e70-4977-ba5e-cde5358eebe2'" class="jump_btn_ch_lmk_ts9" @click="onClickPageLink('https://ecshop.ec-sichuanair.com/wap/?cn=a')"></span>
      <!-- 川航金熊猫权益跳转 -->
      <span v-if="id==='6d2145d9-c3c3-4056-ac28-363c695756cd'" class="jump_btn_ch_lmk_qytz" @click="onClickPageLink('https://www.sichuanair.com/member-advancedMembers/?t=1032')"></span>
    </div>

    <div class="_bottom_fixed" v-if="imgSrc">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home')"></span>
        <span class="_back_prev" @click="jumpGoBack()"></span>
      </div>
    </div>
    <div v-if="id==='7294f6ee-0e3a-4748-b713-bd1da0916e6a'" class="_right_bar_new_card_JDK" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6837950.png"  alt="">
    </div>
    <div  class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png"  alt="">
    </div>

  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import page_links from "../mixin/page_links";
export default {
  components: {},
  mixins: [ page_links ],
  data() {
    return {
      imgSrc: '',
      id:""
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },

  mounted() {
    this.getImageShowData();
    this.getJsInfo();
  },

  methods: {
    jumpGoBack(){
      if(this.$route.query.callbackUrl){
        window.location.href=this.$route.query.callbackUrl;
      }else{
        this.$router.go(-1);
      }
    },
    async getJsInfo(){
      // 生成网页js签名
      let {data,code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if(code===200){
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function( ){
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function(res){
        console.info("wx error",res);
      });
    },
    async getImageShowData() {
      this.id = this.$route.query.id;
      if( this.id){
        let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
          "METHOD": "get",
          "id":  this.id
        });
        if(code===200){
          this.imgSrc =data.resources_img;
        }
      }
      if(this.$route.query.img){
        this.imgSrc =this.$route.query.img;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.show-img-wrapper {
  min-height: 20rem;
  background: #f6f6f6;
  ._pic {
    img {
      display: block;
      width: 100%;
    }
  }
  .jump_btn_slk_nz_a{
    position: absolute;
    width: 3.5rem;
    left: 1.5rem;
    height: 1.5rem;
    top:28rem;
  }
  .jump_btn_slk_nz_c{
    position: absolute;
    width: 3.5rem;
    left: 5.3rem;
    height: 1.5rem;
    top:28rem;
  }
  .jump_btn_slk_nz_b{
    position: absolute;
    width: 7rem;
    left: 1.5rem;
    height: 1.3rem;
    top:48rem;
  }
  .jump_btn_ch_lmk_ts1{
    position: absolute;
    width: 9rem;
    left: 0.5rem;
    height: 1.3rem;
    top:34.3rem;
  }

  .jump_btn_ch_lmk_ts2{
    position: absolute;
    width: 9rem;
    left: 0.5rem;
    height: 1.3rem;
    top:34.3rem;
  }
  .jump_btn_ch_lmk_ts3a{
    position: absolute;
    width: 4rem;
    left: 1rem;
    height: 1.1rem;
    top:8.5rem;
  }
  .jump_btn_ch_lmk_ts3b{
    position: absolute;
    width: 4rem;
    left: 5rem;
    height: 1.1rem;
    top:8.5rem;
  }
  .jump_btn_ch_lmk_ts4{
    position: absolute;
    width: 9rem;
    left: 0.5rem;
    height: 1.3rem;
    top:34.3rem;
  }
  .jump_btn_ch_lmk_ts5{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:11.8rem;
  }
  .jump_btn_ch_lmk_ts6{
    position: absolute;
    width: 9rem;
    left: 0.5rem;
    height: 1.3rem;
    top:39.5rem;
  }


  .jump_btn_ch_lmk_ts7a{
    position: absolute;
    width: 4rem;
    left: 1rem;
    height: 1.1rem;
    top:8.5rem;
  }
  .jump_btn_ch_lmk_ts7b{
    position: absolute;
    width: 4rem;
    left: 5rem;
    height: 1.1rem;
    top:8.5rem;
  }

  .jump_btn_ch_lmk_ts8a{
    position: absolute;
    width: 4rem;
    left: 1rem;
    height: 1.1rem;
    top:8.5rem;
  }
  .jump_btn_ch_lmk_ts8b{
    position: absolute;
    width: 4rem;
    left: 5rem;
    height: 1.1rem;
    top:8.5rem;
  }
  .jump_btn_ch_lmk_ts8c{
    position: absolute;
    width: 8.5rem;
    left: 0.8rem;
    height: 1.1rem;
    top:17.5rem;
  }
  .jump_btn_ch_lmk_ts9{
    position: absolute;
    width: 9rem;
    left: 0.5rem;
    height: 1.3rem;
    top:34.2rem;
  }
  .jump_btn_ch_lmk_qytz{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:27.1rem;
  }

  .jump_btn_zsk_lp_a{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.8rem;
    top:15rem;
  }
  .jump_btn_zsk_lp_b{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.8rem;
    top:19rem;
  }
  .jump_btn_zsk_dk_a{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.7rem;
    top:19rem;
  }

  .jump_btn_jsz_bxga{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.5rem;
    top:24rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_a{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:77rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_b{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:80rem;
    z-index: 999;
  }
  .jump_btn_jsz_wfj{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:60.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_1210_a{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:44.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_1210_b{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:47.5rem;
    z-index: 999;
  }
  .jump_btn_jdk_bx{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:23.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_bx{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:24rem;
    z-index: 999;
  }
  ._right_bar_new_card{
    position: fixed;
    top: 0.3rem;
    right: 0rem;
    img {
      height: 2.7rem;
    }
  }
  ._right_bar_new_card_JDK{
    position: fixed;
    top: 3.1rem;
    right: 0rem;
    img {
      height: 4rem;
    }
  }

}
</style>
